<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-feedback">
						<div class="personal-feedback-line">
							<div class="personal-feedback-tips flex">
								<div class="personal-feedback-text">
									意见内容
								</div>
								<div class="personal-feedback-xin">
									*
								</div>
							</div>
							<input class="personal-feedback-intext" placeholder="请填写您的建议" v-model="content" />
						</div>
						<div class="personal-feedback-uppic">
							<div class="personal-feedback-uptips">上传图片（3张）</div>
							<div class="personal-feedback-picbox flex">
								<el-upload :action="baseURL+'/module/attachment/add'"
									list-type="picture-card" :limit="3" 
									:on-success="success"
									:on-preview="handlePictureCardPreview" 
									:on-remove="handleRemove" :headers="headers">
									<i class="el-icon-plus"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible" size="tiny">
									<img width="100%" :src="dialogImageUrl" alt="">
								</el-dialog>
							</div>
							<div class="personal-feedback-bigbtn jump" @click="confirm()">确认</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	import store from '@/store'
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			Header
		},
		data() {
			return {
				menuPic:'',
				sortIndex: 6,
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
				orderNav: ['全部订单', '待确认', '已完成', '已取消'],
				dialogImageUrl: '',
				dialogVisible: false,
				headers:{
					token:store.state.userToken,
				},
				content:'',
				urls:[],
				baseURL: process.env.VUE_APP_BASE_API,
			}
		},
		computed: {
			...mapState(['userInfo']),
		},
		mounted() {
			// console.log(this.baseURL);
			this.$refs.Header.setIndex(-1,);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			console.log(this.userInfo,'用户信息')
		},
		methods: {
			sortSwitch(index) {
				this.sortIndex = index;
				this.$router.push({
					path: this.nav[index].href
				});
			},
			//文件上传相关
			handleRemove(file, fileList) {
				console.log(file,'文件信息', fileList,'fileListfileListfileList');
				this.$api.removeFile(file.response.data.id).then(res=>{
					this.urls.splice(this.urls.indexOf(file.response.data.url));
					console.log(this.urls);
					// alert('删除成功');
				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//文件上传成功
			success(file){
				console.log(file,'文件信息');
				console.log(this.urls);
				this.urls.push(file.data.url);
			},
			//提交反馈意见
			confirm(){
				if(this.content.length<1){
					this.$alert('感谢您的反馈', '系统提示');
					return;
				}
				var data={
					tel:this.userInfo.tel,
					content:this.content,
					picture:this.urls.toString(),
				};
				this.$api.feedBack(data).then(res=>{
					console.log(res,'提交返回');
					this.$router.push({
						path:'personal'
					})
					this.$alert('感谢您的反馈', '系统提示');
				});
			}
		},
	};
</script>
<style scoped>
</style>
